import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-503c788d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "nav"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "info"
};
const _hoisted_5 = {
  class: "des"
};
import domain from "@/service/domain";
import router from '@/router';
import { onMounted, ref, reactive } from "vue";
import { Icon } from 'vant';
import { getUserPromotionList } from "@/api";
import { mdToast } from "../../utils/tools";
export default {
  setup(__props, {
    expose
  }) {
    const nav_index = ref(0);
    const navList = [{
      title: '全部',
      status: '-1'
    }, {
      title: '待审核',
      status: '0'
    }, {
      title: '进行中',
      status: '10'
    }, {
      title: '已结束',
      status: '20'
    }, {
      title: '已取消',
      status: '30'
    }];
    const state = reactive({
      record_status: -1,
      //-1全部 0待审核 10审核通过 20已结束 30已取消
      page: 1,
      page_size: 20,
      taskList: [],
      refreshing: false,
      is_next: true,
      loading: false
    });
    onMounted(() => {
      console.log('推广服务切换----->我的推广');
      onRefresh();
    });
    const handleRefreshAction = () => {
      console.log('推广服务切换----->refresh2');
      onRefresh();
    };
    function handleClickNav(item, index) {
      nav_index.value = index;
      console.log('index----', index);

      // 按状态请求我的推广
      state.record_status = item.status;
      onRefresh();
    }

    // 下拉刷新
    function onRefresh() {
      state.page = 1;
      requestPromotionList();
    }

    // 上拉加载
    function onLoadMore() {
      requestPromotionList();
    }
    async function requestPromotionList() {
      state.loading = true;
      const {
        status,
        data,
        message
      } = await getUserPromotionList({
        page: state.page,
        page_size: state.page_size,
        record_status: state.record_status
      });
      state.loading = false;
      state.is_next = data.is_next;
      state.refreshing = false;
      if (status == 0) {
        if (state.page == 1) {
          state.taskList.splice(0, state.taskList.length);
        }
        if (data.list && data.list.length > 0) {
          state.taskList.push(...data.list);
          state.page++;
        }
      } else {
        mdToast(message);
      }
    }
    function handleClickAction(item) {
      console.log('点击我的推广-----', item);

      // eslint-disable-next-line no-undef
      graft.app({
        functionName: "openController",
        functionParams: `${domain.wap}/score/promotion/user_detail/${item.record_id}`,
        webFun: () => {
          router.push({
            path: `/score/promotion/user_detail/${item.record_id}`
          });
        }
      });
    }
    function statusNameFormatter(status) {
      var status_name = '';
      switch (Number(status)) {
        case 0:
          status_name = '待审核';
          break;
        case 10:
          status_name = '进行中';
          break;
        case 20:
          status_name = '已完成';
          break;
        case 1:
        case 2:
          status_name = '审核拒绝';
          break;
        case 30:
          status_name = '已取消';
          break;
        default:
          break;
      }
      return status_name;
    }
    function statusNameStyle(status) {
      let style = {
        'color': '#7F7F7F',
        'fontSize': '12px'
      };
      switch (status) {
        case 0:
          //待审核
          style = {
            'color': '#FF3535',
            'fontSize': '12px'
          };
          break;
        case 10:
          {
            // 进行中
            style = {
              'color': '#00C4A1',
              'fontSize': '12px'
            };
          }
          break;
        case 3:
        case 4:
        default:
          break;
      }
      return style;
    }

    // 向外暴露
    expose({
      handleRefreshAction
    });
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_list = _resolveComponent("van-list");
      const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");
      const _component_Error = _resolveComponent("Error");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(navList, (item, index) => {
        return _createElementVNode("div", {
          class: _normalizeClass(['nav-item', {
            'selected': nav_index.value == index
          }]),
          key: index,
          onClick: _withModifiers($event => handleClickNav(item, index), ["stop"])
        }, _toDisplayString(item.title), 11, _hoisted_2);
      }), 64))]), _unref(state).taskList.length > 0 ? (_openBlock(), _createBlock(_component_van_pull_refresh, {
        key: 0,
        modelValue: _unref(state).refreshing,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(state).refreshing = $event),
        onRefresh: onRefresh
      }, {
        default: _withCtx(() => [_createVNode(_component_van_list, {
          class: "list",
          loading: _unref(state).loading,
          "onUpdate:loading": _cache[0] || (_cache[0] = $event => _unref(state).loading = $event),
          finished: !_unref(state).is_next,
          "immediate-check": false,
          onLoad: onLoadMore
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).taskList, (item, index) => {
            return _openBlock(), _createElementBlock("div", {
              class: "list-card",
              key: index,
              onClick: _withModifiers($event => handleClickAction(item), ["stop"])
            }, [_createVNode(_component_van_image, {
              class: "icon",
              src: _ctx.$imgUrlFilter(item.icon, 96)
            }, null, 8, ["src"]), _createElementVNode("div", _hoisted_4, [_createTextVNode(_toDisplayString(item.title) + " ", 1), _createElementVNode("div", _hoisted_5, _toDisplayString(item.desc), 1)]), _createElementVNode("div", {
              class: "status-name",
              style: _normalizeStyle(statusNameStyle(item.status))
            }, _toDisplayString(statusNameFormatter(item.status)), 5), _createVNode(_unref(Icon), {
              class: "status-arrow",
              name: _ctx.$imgUrlFilter('https://p6.moimg.net/mdwxapp/score_mypromotion_arrow.png', 48)
            }, null, 8, ["name"])], 8, _hoisted_3);
          }), 128))]),
          _: 1
        }, 8, ["loading", "finished"])]),
        _: 1
      }, 8, ["modelValue"])) : !_unref(state).loading ? (_openBlock(), _createBlock(_component_Error, {
        key: 1,
        message: "什么都没有哦",
        class: "empty-box"
      })) : _createCommentVNode("", true)], 64);
    };
  }
};